@tailwind base;
@tailwind components;
@tailwind utilities;

@import './components';

html {
  scroll-behavior: smooth;
}
body {
  font-family: var(--font-inter);
}
:root {
  --message-box-code-background: rgba(229, 231, 235, 0.2);
  --wrapper-dynamic-grid-template-rows: 48px 1fr auto;
  --conversation-list-radius: 0px;
  --header-height: 48px;
  --header-min-height: auto;
  --header-justify-content: space-between;
  --header-agents-display: none;
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}
